import React from "react";

function Footer() {
  return (
    <div class="text-center p-6 bg-black footer">
      <span class="text-white">&copy; 2023 Copyright - </span>
      <a
        class="link-underline text-white font-bold"
        href="http://www.lowerkeyspaintingandmaintenance.com"
      >
        LowerKeysPaintingAndMaintanance
      </a>
    </div>
  );
}

export default Footer;
