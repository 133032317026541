import React from "react";

function Sitemap() {
  const sitemapXml = `<?xml version="1.0" encoding="UTF-8"?>
  <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
          xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
          http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    <url>
      <loc>http://www.lowerkeyspaintingandmaintenance.com/</loc>
      <lastmod>2023-04-25T16:18:21+00:00</lastmod>
    </url>
  </urlset>`;

  return <div dangerouslySetInnerHTML={{ __html: sitemapXml }} />;
}

export default Sitemap;
